import React, { useState, useContext, useEffect } from "react";
import Layout from "components/Layout";
import Header from "components/Header";
import Seo from "components/seo";
import Jobs from "lib/jobs";

import { Stack, Button, Container } from "@mui/material";

import BunfanTextLogo from "assets/logos/bunfan-text-logo.jpg";

const Index = () => (
	<Layout>
		<Header />
		<Seo
			title="Staff"
			description="We are the BunFan Games Core Development Team."
			image={BunfanTextLogo}
		/>
		<Container maxWidth="xl">Hello World</Container>
	</Layout>
);

export default Index;
